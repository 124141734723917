const enUS = {
  // message:
  //   "Hello! Welcome to the world of Lavector's AI fragrance. I am your exclusive fragrance consultant. First, we can help you find the most suitable fragrance through some small questions. Would you like to give it a try?",
  // dialogue: 'Intelligent assistant enters the conversation to serve you',
  inputVal: 'Please enter...',
  think: 'Thinking...',
  upLoadPic: 'Upload picture',
}

export default enUS
