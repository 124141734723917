import Cookies from 'js-cookie'

const TokenKey =
  'access-token-unsecure=SFe_s7LywkNcoQKLLWtPiA; _ga=GA1.1.1398965633.1686019111; _gid=GA1.1.1470966684.1686019111; _gat_gtag_UA_156449732_1=1; _dd_s=logs=1&id=acd14edf-765e-43c3-b158-5a533d32b38f&created=1686032181563&expire=1686033106697'
// 获取token的方法
export function getToken() {
  return Cookies.get(TokenKey)
}
// 设置token
export function setToken(token) {
  return Cookies.set(TokenKey, token)
}
// 删除cookies里的token
export function removeToken() {
  return Cookies.remove(TokenKey)
}
