import React, { Component, createContext } from 'react'
import { LocaleProvider } from '@chatui/core'
import Main from './components/main/main'
import Cookies from 'js-cookie'
import zhCN from './static/locale/zhCN'
import enUS from './static/locale/enUS'

export const MyContext = createContext()
var user_id

class App extends Component {
  state = {
    locale: '',
  }

  componentDidMount() {
    if (sessionStorage.getItem('user_id')) {
      user_id = sessionStorage.getItem('user_id')
    } else {
      user_id = 'userid' + Math.random().toString().slice(2)
      sessionStorage.setItem('user_id', user_id)
    }

    let lang = ''
    if (Cookies.get('local')) {
      lang = Cookies.get('local')
    } else {
      lang = 'en-US'
      Cookies.set('local', lang)
    }

    if (lang === 'zh-CN') {
      this.setState({
        locale: 'zh-CN',
      })
    } else {
      this.setState({
        locale: 'en-US',
      })
    }
  }

  render() {
    const { locale } = this.state
    return (
      <LocaleProvider locales={locale === 'zh-CN' ? zhCN : enUS}>
        <MyContext.Provider
          value={{
            user_id,
            changeLanguage: (lang) => {
              Cookies.set('local', lang)
              this.setState(
                {
                  locale: lang,
                },
                () => {
                  window.location.reload()
                },
              )
            },
          }}
        >
          <Main />
        </MyContext.Provider>
      </LocaleProvider>
    )
  }
}
export default App
