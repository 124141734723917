const zhCN = {
  // message:
  //   '您好！欢迎来到菱歌的AI香氛的世界，我是您的专属香氛顾问哦!首先我们可以通过一些小问题来帮助您找到最适合的香氛，您愿意试试吗？',
  // dialogue: '智能助理进入对话，为您服务',
  inputVal: '请输入...',
  think: '思考中...',
  upLoadPic: '上传图片',
}

export default zhCN
