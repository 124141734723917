import axios from 'axios'
import { getToken, setToken } from './auth'

const TokenKey =
  'access-token-unsecure=SFe_s7LywkNcoQKLLWtPiA; _ga=GA1.1.1398965633.1686019111; _gid=GA1.1.1470966684.1686019111; _gat_gtag_UA_156449732_1=1; _dd_s=logs=1&id=acd14edf-765e-43c3-b158-5a533d32b38f&created=1686032181563&expire=1686033106697'
setToken(TokenKey)

// 创建一个axios实例
const instance = axios.create({
  baseURL: '',
  timeout: 60000,
  //   withCredentials: true, // 允许携带token ,这个是解决跨域产生的相关问题
  headers: {
    'Content-Type': 'application/json',
  },
})
/**
 *
 * 请求拦截
 */

instance.interceptors.request.use(
  (config) => {
    config.headers['Authorization'] = getToken() // 请求头带上token
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

// instance.setToken = (token) => {
//   instance.defaults.headers['Authorization'] = token
//     storage.save('token', token)
// }
/**
 * 响应拦截
 */

instance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    return Promise.reject(error)
  },
)

const http = {
  post: function (url, params) {
    return new Promise((resolve, reject) => {
      instance
        .post(url, JSON.stringify(params))
        .then((response) => {
          // console.log(response)
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

export default http
