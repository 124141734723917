import React, { useState, useRef, useContext, useEffect } from "react";
import { useLocale } from "@chatui/core";
import "../../App.css";
import "./main.css";
// 引入组件
// import Chat, {
//   Bubble,
//   useMessages,
//   List,
//   ListItem,
//   Flex,
//   FlexItem,
//   Button,
//   Card,
//   CardTitle,
//   RadioGroup,
//   toast,
//   Icon,
// } from '@chatui/core'
import {
  Form,
  Input,
  Button,
  message,
  Checkbox,
  Card,
  Spin,
  Divider,
  Image,
} from "antd";
import http from "../../utils/http";
import ReactEcharts from "echarts-for-react";
import robot from "../../static/image/outbot.png";
import userImg from "../../static/image/userIcon1.png";
import Support from "../../static/image/zan.png";
import SupportH from "../../static/image/zanHover.png";
import Oppose from "../../static/image/cai.png";
import OpposeH from "../../static/image/caiHover.png";
import sendShowIcon from "../../static/image/sendShow.png";
import sendIcon from "../../static/image/send.png";
import logo from "../../static/aboutlogo.png";
import LanguageIcon from "../../static/image/Language-copy.png";

import { marked } from "marked";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import { MyContext } from "../../App";
import Cookies from "js-cookie";

import User from "../../static/image/user.jpg";
import Bot from "../../static/image/bot.jpg";
import Check from "../../static/image/check.jpg";
import below1 from "../../static/images/ProductCategory/below1.jpeg";
import below2 from "../../static/images/ProductCategory/below2.jpeg";
import below3 from "../../static/images/ProductCategory/below3.jpeg";
import below4 from "../../static/images/ProductCategory/below4.jpeg";
import below5 from "../../static/images/ProductCategory/below5.jpeg";
import below6 from "../../static/images/ProductCategory/below6.jpeg";
import scond1 from "../../static/images/TA/scond1.jpeg";
import scond2 from "../../static/images/TA/scond2.jpg";
import scond3 from "../../static/images/TA/scond3.jpeg";
import scond4 from "../../static/images/TA/scond4.jpg";
import scond5 from "../../static/images/TA/scond5.jpeg";
import woody1 from "../../static/images/productScent/woody1.jpg";
import woody2 from "../../static/images/productScent/woody2.jpg";
import woody3 from "../../static/images/productScent/woody3.jpg";
import woody4 from "../../static/images/productScent/woody4.jpg";
import woody5 from "../../static/images/productScent/woody5.jpg";
import woody6 from "../../static/images/productScent/woody6.jpg";
import scent1 from "../../static/images/ScentFeeling/scent1.jpeg";
import scent2 from "../../static/images/ScentFeeling/scent2.jpg";
import scent3 from "../../static/images/ScentFeeling/scent3.jpg";
import scent4 from "../../static/images/ScentFeeling/scent4.jpg";
import scent5 from "../../static/images/ScentFeeling/scent5.jpg";
import scent6 from "../../static/images/ScentFeeling/scent6.jpg";
import scent7 from "../../static/images/ScentFeeling/scent7.jpeg";
import cozy1 from "../../static/images/Mood/cozy1.jpg";
import cozy2 from "../../static/images/Mood/cozy2.jpeg";
import cozy3 from "../../static/images/Mood/cozy3.jpeg";
import cozy4 from "../../static/images/Mood/cozy4.jpeg";
import cozy5 from "../../static/images/Mood/cozy5.jpeg";
import cozy6 from "../../static/images/Mood/cozy6.jpg";
import cozy7 from "../../static/images/Mood/cozy7.jpg";

// {
//   data: [
//     { value: '111' },
//     { value: '222' },
//     { value: '333' },
//     { value: '444' },
//     { value: '555' },
//   ],
//   type: 'bar',
//   color: '#ff8740',
//   barWidth: '20px',
//   itemStyle: {
//     normal: {
//       label: {
//         show: true, //开启显示
//         position: 'right', //在上方显示
//       },
//     },
//   },
// label: {
//   normal: {
//     formatter: function (obj) {
//       return (
//         obj.value +
//         '<img src="' +
//         Hua +
//         '" style="width:14px;height:14px;vertical-align: middle;margin-bottom: 4px;margin-left:3px" />'
//       )
//     },
//   },
// },
// },

const language = Cookies.get("local");
const { Meta } = Card;

const Main = () => {
  const [inputVal, setInputVal] = useState("");
  const [echartsHeight, setEchartsHeight] = useState("200px");
  const [visibilityValue, setvisibilityValue] = useState({
    visibility: "hidden",
  });
  const [form] = Form.useForm();
  const [imgsPic, setImgsPic] = useState([
    { name: "Detergent Liquid", img: below1 },
    { name: "Scented Beads", img: below2 },
    { name: "Capsule", img: below3 },
    { name: "Home Spray", img: below4 },
    { name: "Fabric Conditioner", img: below5 },
    { name: "Perfume", img: below6 },
  ]);
  const [imgsPicScond, setImgsPicScond] = useState([
    { name: "Gen Z", img: scond1 },
    { name: "Super Mom", img: scond2 },
    { name: "Small Town Youth", img: scond3 },
    { name: "White Collar", img: scond4 },
    { name: "Silver Gen", img: scond5 },
  ]);
  const [imgsPicwoody, setImgsPicwoody] = useState([
    { name: "Woody", img: woody1 },
    { name: "Floral", img: woody2 },
    { name: "Green", img: woody3 },
    { name: "Citrus", img: woody4 },
    { name: "Oriental/Spicy", img: woody5 },
    { name: "Fruity", img: woody6 },
  ]);
  const [imgsPicscent, setImgsPicscent] = useState([
    { name: "Clean", img: scent1 },
    { name: "Natural", img: scent2 },
    { name: "Fresh", img: scent3 },
    { name: "Gentle", img: scent4 },
    { name: "Sweet", img: scent5 },
    { name: "Warm", img: scent6 },
    { name: "Luxurious", img: scent7 },
  ]);
  const [imgsPiccozy, setImgsPiccozy] = useState([
    { name: "Relaxing", img: cozy1 },
    { name: "Enjoyable", img: cozy2 },
    { name: "Confident", img: cozy3 },
    { name: "Positive", img: cozy4 },
    { name: "Energising", img: cozy5 },
    { name: "Comforting", img: cozy6 },
    { name: "Caring", img: cozy7 },
  ]);
  const [radioValue, setRadioValue] = useState();
  const [radioValueScond, setRadioValueScond] = useState();
  const [radioValueWoody, setRadioValueWoody] = useState();
  const [radioValueScent, setRadioValueScent] = useState();
  const [radioValueCozy, setRadioValueCozy] = useState();
  const [item1, setItem1] = useState(null);
  const [item2, setItem2] = useState(null);
  const [item3, setItem3] = useState(null);
  const [item4, setItem4] = useState(null);
  const [item5, setItem5] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dataAnalysis, setDataAnalysis] = useState([]);
  const [relatedTop, setRelatedTop] = useState([]);
  const [relatedMiddle, setRelatedMiddle] = useState([]);
  const [relatedBase, setRelatedBase] = useState([]);
  const [relatedIngredients, setRelatedIngredients] = useState([]);
  const [sexData, setSexData] = useState({});
  const [dataMeta, setDataMeta] = useState([]);
  const [generateImage, setGenerateImage] = useState({});
  const [materialsData, setMaterialsData] = useState(null);
  const [description, setDescription] = useState(null);
  const [claim, setClaim] = useState(null);

  const handleChange = (e) => {
    console.log(e.target.value);
    if (radioValue && radioValue === e.target.value) {
      setInputVal("");
      e.target.checked = !e.target.checked;
    } else {
      setInputVal(e.target.value);
    }
  };
  // 图片
  const onChange = (e) => {
    if (e.length > 0) {
      var a = [e[e.length - 1]];
      setRadioValue(a);
      setItem1([
        "Firstly, let's confirm your product category. Please select one from the list below",
        a[0],
      ]);
    } else {
      setRadioValue(e);
      setItem1([
        "Firstly, let's confirm your product category. Please select one from the list below",
        [""],
      ]);
    }
  };
  const onChangeScond = (e) => {
    if (e.length > 0) {
      var a = [e[e.length - 1]];
      setRadioValueScond(a);
      setItem2(["Secondly, who's your target audience?", a[0]]);
    } else {
      setRadioValueScond(e);
      setItem2(["Secondly, who's your target audience?", [""]]);
    }
  };
  const onChangeWoody = (e) => {
    setRadioValueWoody(e);
    setItem3(
      e && e.length >= 1
        ? [
            "Great! We will design a product scent together. Let’s describe an ideal scent and this will include notes, scent feeling and scent moods! Could you pick the note for me?",
            e.join(", "),
          ]
        : null
    );
  };
  const onChangeScent = (e) => {
    setRadioValueScent(e);
    setItem4(
      e && e.length >= 1
        ? ["Thanks! Could you describe the scent feeling?", e.join(", ")]
        : null
    );
  };
  const onChangeCozy = (e) => {
    setRadioValueCozy(e);
    setItem5(
      e && e.length >= 1
        ? [
            "Almost there! Which mood would you like the scent to evoke?",
            e.join(", "),
          ]
        : null
    );
  };
  // 提交
  const submitBtn = () => {
    const params = [];
    if (item1) {
      params.push(item1);
    }
    if (item2) {
      params.push(item2);
    }
    if (item3) {
      params.push(item3);
    }
    if (item4) {
      params.push(item4);
    }
    if (item5) {
      params.push(item5);
    }
    if (params.length === 0) {
      message.open({
        type: "warning",
        content: "Please select at least one.",
      });
      return;
    }

    const data = {
      question: "this is a survey",
      prompt: "survey_prompt",
      kb_names: ["english"],
      history: params,
    };
    message.open({
      type: "success",
      content: "In data analysis, please wait.",
    });
    http
      .post("https://images.lavector.com/api/questionnaire", data)
      .then((res) => {
        setvisibilityValue({ visibility: "visible" });
        setDataAnalysis(res.data.response);
        let info = [];
        res.data.source_documents.map((item) => {
          info.push(item.meta_info);
        });
        setTimeout(function () {
          message.open({
            type: "success",
            content: "Drawing graphs, please wait.",
          });

          GenerateImg(params, res.data.response, info);
          // window.scrollTo(0, 10000); // 最底部

          //true: 元素的顶端将和其所在滚动区的可视区域的顶端对齐; false: 元素的底端将和其所在滚动区的可视区域的底端对齐
          // document.getElementById("dataAnalysis").scrollIntoView(true);
          setLoading(true);
        }, 1000);
      });
  };
  const GenerateImg = (survey, answer, meta) => {
    const option = {
      title: {
        show: false,
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
        formatter(params) {
          // 返回值就是html代码可以使用标签
          return `
			${params[0].axisValue}:${params[0].value}%
		
			`;
        },
      },
      grid: {
        left: "1%",
        right: "2%",
        top: "1%",
        bottom: "1%",
        containLabel: true,
      },
      xAxis: {
        show: true,
        type: "value",
        axisLabel: {
          formatter: function (value, index) {
            return value + "%";
          },
        },
        data: [],
        // axisLine: {
        //   lineStyle: {
        //     color: '#d8d8d8',
        //   },
        // },
        // axisLabel: {
        //   show: false,
        //   rotate: 45,
        //   textStyle: {
        //     color: '#515151',
        //   },
        // },
        splitLine: {
          show: false,
        },
        boundaryGap: [0, 0.01],
      },
      yAxis: {
        type: "category",
        show: true,
        data: [],
        // axisLine: {
        //   lineStyle: {
        //     color: '#CCCCCC',
        //   },
        // },

        axisLine: {
          show: false, // 不显示坐标轴线
        },
        axisLabel: {
          show: true, // 显示坐标轴上的文字
        },
        splitLine: {
          show: false, // 不显示网格线
        },
        axisTick: {
          //y轴刻度线
          show: false, // 不显示坐标轴刻度线
        },
        // axisLabel: {
        //   textStyle: {
        //     color: '#636363',
        //   },
        // },
        // splitLine: {
        //   lineStyle: {
        //     type: 'dashed',
        //   },
        // },
      },
      series: [
        {
          name: "hill",
          type: "bar",
          barWidth: "30px",
          barCategoryGap: "10%",
          data: [],
          // z: 10,
          // color: ['#FA897B', '#FA897B', '#D0E6A5'],
          itemStyle: {
            normal: {
              label: {
                show: true, //开启显示
                position: "right", //在上方显示
                formatter: function (params) {
                  return params.value + "%"; // 添加百分号
                },
              },

              color: function (params) {
                let colorList = [
                  "#FA897B",
                  "#FFDD94",
                  "#D0E6A5",
                  "#5470c6",
                  "#91cc75",
                  "#fac858",
                  "#ee6666",
                  "#73c0de",
                  "#3ba272",
                  "#fc8452",
                  "#9a60b4",
                  "#ea7ccc",
                  "#FF0033",
                  "#a5e183",
                  "#7bc4e8",
                ];
                let colorItem = colorList[params.dataIndex];
                return colorItem;
              },
            },
          },
        },
        {
          name: "glyph",
          type: "pictorialBar",
          barCategoryGap: "10%",
          symbolPosition: "end",
          symbolSize: 30,
          symbolOffset: ["200%", 0],
          data: [],
        },
      ],
    };
    const data = { survey, answer, meta, generate_image: false };
    http.post("https://images.lavector.com/api/process", data).then((res) => {
      setLoading(false);
      setMaterialsData(res.data.display_data.materials_data);
      setRelatedTop(res.data.display_data.related_top_notes); //前调
      setRelatedMiddle(res.data.display_data.related_middle_notes); //中调
      setRelatedBase(res.data.display_data.related_base_notes); //后
      let relatedIngredients = res.data.display_data.related_ingredients; //柱状图数据 {image: "月季/25074879_25074879_1377809358687.jpg", name: "Rose", score: 13}
      setEchartsHeight(100 + relatedIngredients.length * 30);
      const yArray = [];
      const seriesData = [];
      const seriesData1 = [];
      relatedIngredients.map((item) => {
        yArray.push(item.name);
        seriesData.push(item.score);
        let obj = {
          value: item.score,
          symbol: "image://https://img.lavector.com/scentai/" + item.image,
          symbolSize: [30, 30],
        };
        seriesData1.push(obj);
      });
      option.yAxis.data = yArray;
      // option.xAxis.data = seriesData
      option.series[0].data = seriesData;
      option.series[1].data = seriesData1;
      setSexData(option);
      setDataMeta(data.meta); //商品信息
      // setGenerateImage(res.data.display_data.generate_image); //生成的独立图片

      const imgData = {
        survey,
        answer,
        meta,
        display_data: res.data.display_data,
      };
      http
        .post("https://images.lavector.com/api/last_generate", imgData)
        .then((res) => {
          setGenerateImage(res.data.generate_image); //生成的独立图片
          http
            .post(
              "https://images.lavector.com/api/generate_description",
              imgData
            )
            .then((res) => {
              setDescription(res.data.description);
              http
                .post("https://images.lavector.com/api/generate_claim", {
                  ...imgData,
                  product_description: res.data.description,
                })
                .then((res) => {
                  setClaim(res.data.claim);
                });
            });
        });
    });
  };
  //
  // const JumpLink = (url)(
  //   window.
  // )

  return (
    <div className="main">
      <div className="mainBox">
        {/* 左侧 */}
        <div className="mainLeft">
          <h2 className="leftTitle">ScentAI</h2>
          <div className="content">
            <div className="contentItem">
              <div className="item">
                <img
                  style={{ width: 36, height: 36, marginRight: 15 }}
                  src={Bot}
                />
                <p>
                  Hello, I am your ScentAl consultant. I can help you design the
                  perfect scent that's pursued by real consumers. Let's start
                  with you scent project! How would you like to call your
                  project？
                </p>
              </div>
              <div className="item">
                <img
                  style={{ width: 36, height: 36, marginRight: 15 }}
                  src={User}
                />
                <Input value={inputVal} onChange={handleChange} />
              </div>
            </div>
            {/* form */}
            {/* <div className="contentItem">
              <div className="item">
                <img
                  style={{ width: 36, height: 36, marginRight: 15 }}
                  src={Bot}
                />
                <p>
                  Firstly, let's confirm your product category. Please select
                  one from the list below:
                </p>
              </div>
              <div className="item">
                <div className="radioGroup">
                  <Checkbox.Group onChange={onChange} value={radioValue}>
                    {imgsPic.map((item) => (
                      <Checkbox value={item.name}>
                        <i className="radioflag"></i>
                        <img style={{ width: 80 }} src={item.img} />
                      </Checkbox>
                    ))}
                  </Checkbox.Group>
                </div>
              </div>
            </div> */}

            <div className="contentItem">
              <div className="item">
                <img
                  style={{ width: 36, height: 36, marginRight: 15 }}
                  src={Bot}
                />
                <p>
                  Firstly, let's confirm your product category. Please select
                  one from the list below:
                </p>
              </div>
              <div className="item">
                <div className="radioGroup">
                  <Checkbox.Group onChange={onChange} value={radioValue}>
                    {imgsPic.map((item) => (
                      <Checkbox
                        value={item.name}
                        style={{ marginBottom: "10px", marginRight: "-10px" }}
                      >
                        <i className="radioflag"></i>
                        <div className="checkText">
                          <img
                            style={{ width: 120, height: 140 }}
                            src={item.img}
                          />
                          <p style={{ height: 30, lineHeight: 2 }}>
                            {item.name}
                          </p>
                        </div>
                      </Checkbox>
                    ))}
                  </Checkbox.Group>
                </div>
              </div>
            </div>

            <div className="contentItem">
              <div className="item">
                <img
                  style={{ width: 36, height: 36, marginRight: 15 }}
                  src={Bot}
                />
                <p>Secondly, who's your target audience?</p>
              </div>
              <div className="item">
                <div className="radioGroup">
                  <Checkbox.Group
                    onChange={onChangeScond}
                    value={radioValueScond}
                  >
                    {imgsPicScond.map((item) => (
                      <Checkbox
                        value={item.name}
                        style={{ marginBottom: "10px", marginRight: "-10px" }}
                      >
                        <i className="radioflag"></i>
                        <div className="checkText">
                          <img
                            style={{ width: 120, height: 140 }}
                            src={item.img}
                          />
                          <p style={{ height: 30, lineHeight: 2 }}>
                            {item.name}
                          </p>
                        </div>
                      </Checkbox>
                    ))}
                  </Checkbox.Group>
                </div>
              </div>
            </div>
            <div className="contentItem">
              <div className="item">
                <img
                  style={{ width: 36, height: 36, marginRight: 15 }}
                  src={Bot}
                />
                <p>
                  Great! We will design a product scent together. Let’s describe
                  an ideal scent and this will include notes, scent feeling and
                  scent moods! Could you pick the note for me?
                </p>
              </div>

              <div className="item" style={{ marginBottom: 25 }}>
                <div className="radioGroup">
                  <Checkbox.Group
                    onChange={onChangeWoody}
                    value={radioValueWoody}
                  >
                    {imgsPicwoody.map((item) => (
                      <Checkbox
                        value={item.name}
                        style={{ marginBottom: "10px", marginRight: "-10px" }}
                      >
                        <i className="radioflag"></i>
                        <div className="checkText">
                          <img
                            style={{ width: 100, height: 120 }}
                            src={item.img}
                          />
                          <p style={{ height: 30, lineHeight: 2 }}>
                            {item.name}
                          </p>
                        </div>
                      </Checkbox>
                    ))}
                  </Checkbox.Group>
                </div>
              </div>
            </div>
            <div className="contentItem">
              <div className="item">
                <img
                  style={{ width: 36, height: 36, marginRight: 15 }}
                  src={Bot}
                />
                <p>Thanks! Could you describe the scent feeling?</p>
              </div>
              <div className="item" style={{ marginBottom: 25 }}>
                <div className="radioGroup">
                  <Checkbox.Group
                    onChange={onChangeScent}
                    value={radioValueScent}
                  >
                    {imgsPicscent.map((item) => (
                      <Checkbox
                        value={item.name}
                        style={{ marginBottom: "10px", marginRight: "-10px" }}
                      >
                        <i className="radioflag"></i>
                        <div className="checkText">
                          <img
                            style={{ width: 100, height: 120 }}
                            src={item.img}
                          />
                          <p style={{ height: 30, lineHeight: 2 }}>
                            {item.name}
                          </p>
                        </div>
                      </Checkbox>
                    ))}
                  </Checkbox.Group>
                </div>
              </div>
            </div>
            <div className="contentItem">
              <div className="item">
                <img
                  style={{ width: 36, height: 36, marginRight: 15 }}
                  src={Bot}
                />
                <p>
                  Almost there! Which mood would you like the scent to evoke?
                </p>
              </div>
              <div className="item" style={{ marginBottom: 25 }}>
                <div className="radioGroup">
                  <Checkbox.Group
                    onChange={onChangeCozy}
                    value={radioValueCozy}
                  >
                    {imgsPiccozy.map((item) => (
                      <Checkbox
                        value={item.name}
                        style={{ marginBottom: "10px", marginRight: "-10px" }}
                      >
                        <i className="radioflag"></i>
                        <div className="checkText">
                          <img
                            style={{ width: 100, height: 120 }}
                            src={item.img}
                          />
                          <p style={{ height: 30, lineHeight: 2 }}>
                            {item.name}
                          </p>
                        </div>
                      </Checkbox>
                    ))}
                  </Checkbox.Group>
                </div>
              </div>
            </div>
            <div className="contentItem">
              <div className="item" style={visibilityValue}>
                <img
                  style={{ width: 36, height: 36, marginRight: 15 }}
                  src={Bot}
                />
                <p>Great! This is our initial outcome for you!</p>
              </div>
              <div id="dataAnalysis" style={{ marginLeft: "7%" }}>
                {dataAnalysis}
              </div>
            </div>
            <div style={{ textAlign: "center" }}>
              <Button onClick={submitBtn}>Analysis</Button>
            </div>

            {/* <Form
                form={form}
                name="basic"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'end',
                }}
                // onFinish={handleSend}
              >
                <Form.Item
                  label="Firstly, let's confirm your product category. Please select
                  one from the list below:"
                >
                  <i>
                    <img
                      style={{ width: 36, height: 36, marginRight: 15 }}
                      src={Bot}
                    />
                  </i>
                  <Input />
                </Form.Item>
              </Form> */}
          </div>
        </div>
        {/* 右侧 */}
        <div className="mainRight">
          <h2 className="leftTitle">{inputVal ? inputVal : ""}</h2>
          <div className="content">
            <Spin spinning={loading}>
              {/* 香调 */}
              {relatedTop.length > 0 ? (
                <div>
                  <h3 style={{ marginBottom: 20 }}>
                    Unleash the Power of AI for Discovering New Scents
                  </h3>
                  <Divider />
                  <div>
                    <h4 className="fragranceTopTitle">Product Category</h4>
                  </div>
                  <div>{item1 ? item1[1] : ""}</div>
                  <div>
                    <h4 className="fragranceTopTitle">Target Audience</h4>
                  </div>
                  <div>{item2 ? item2[1] : ""}</div>
                  <div>
                    <h4 className="fragranceTopTitle">Main Note</h4>
                  </div>
                  <div>{item3 ? item3[1] : ""}</div>
                  <div>
                    <h4 className="fragranceTopTitle">Feelings</h4>
                  </div>
                  <div>{item4 ? item4[1] : ""}</div>
                  <div>
                    <h4 className="fragranceTopTitle">Mood</h4>
                  </div>
                  <div>{item5 ? item5[1] : ""}</div>
                </div>
              ) : null}

              <div className="fragrance">
                <div className="fragranceTop">
                  {relatedTop.length > 0 ? (
                    <div>
                      <Divider />
                      <h4 className="fragranceTopTitle">
                        Recommended Note Design
                      </h4>

                      <h4 className="fragranceTopTitle">Top note</h4>
                    </div>
                  ) : null}
                  <div className="fragranceTopImg">
                    {relatedTop.map((item) => (
                      <span>
                        <img
                          style={{ width: 80, height: 80, marginRight: 6 }}
                          src={`https://img.lavector.com/scentai/${item.image}`}
                        />
                        <p style={{ wordWrap: "normal", width: 80 }}>
                          {item.name}
                        </p>
                      </span>
                    ))}
                  </div>
                </div>
                <div className="fragranceTop">
                  {relatedMiddle.length > 0 ? (
                    <h4 className="fragranceTopTitle">Middle note</h4>
                  ) : null}
                  <div className="fragranceTopImg">
                    {relatedMiddle.map((item) => (
                      <span>
                        <img
                          style={{ width: 80, height: 80, marginRight: 6 }}
                          src={`https://img.lavector.com/scentai/${item.image}`}
                        />
                        <p style={{ wordWrap: "normal", width: 80 }}>
                          {item.name}
                        </p>
                      </span>
                    ))}
                  </div>
                </div>
                <div className="fragranceTop">
                  {relatedBase.length > 0 ? (
                    <h4 className="fragranceTopTitle">Base note</h4>
                  ) : null}
                  <div className="fragranceTopImg">
                    {relatedBase.map((item) => (
                      <span>
                        <img
                          style={{ width: 80, height: 80, marginRight: 6 }}
                          src={`https://img.lavector.com/scentai/${item.image}`}
                        />
                        <p style={{ wordWrap: "normal", width: 80 }}>
                          {item.name}
                        </p>
                      </span>
                    ))}
                  </div>
                </div>
              </div>

              {JSON.stringify(sexData) !== "{}" ? (
                <div>
                  <Divider />

                  <h4 className="fragranceTopTitle">
                    Popular Accords (Based on Consumer Surveys)
                  </h4>
                </div>
              ) : null}
              {/* 柱形图 */}
              <div className="optionChart">
                <ReactEcharts
                  style={{ height: echartsHeight }}
                  option={sexData}
                  notMerge={true}
                  lazyUpdate={true}
                />
              </div>

              {dataMeta.length > 0 ? (
                <div>
                  <Divider />

                  <h4 className="fragranceTopTitle">Most Related Fragrances</h4>
                </div>
              ) : null}
              {/* 商品 */}
              <div className="dataMetaImg">
                {dataMeta.map((item) => (
                  <span
                    style={{
                      display: "inline-block",
                      width: "30%",
                      marginRight: "10%",
                    }}
                  >
                    <img
                      src={`https://img.lavector.com/scentai/product/${item.product_image}`}
                    />
                    <p>{item.english_name}</p>
                  </span>
                ))}
              </div>

              {/* 图文环绕 */}
              {JSON.stringify(generateImage) !== "{}" ? (
                <div>
                  <Divider />

                  <h4 className="fragranceTopTitle">
                    Package Design Inspiration
                  </h4>

                  <div className="generateImage">
                    <span>
                      <a
                        href={`https://images.lavector.com/${generateImage.image_path}`}
                        target="_blank"
                      >
                        <img
                          className="generateImg"
                          src={`https://images.lavector.com/${generateImage.image_path}`}
                        />
                      </a>
                      {/* <p>{generateImage.positive_prompt}</p> */}
                    </span>
                  </div>
                </div>
              ) : null}

              {/* 香调 */}
              {relatedTop.length > 0 ? (
                <div>
                  <Divider />
                  <div>
                    <h4 className="fragranceTopTitle">
                      Top Matching Ingredients
                    </h4>
                  </div>
                  {materialsData.map((item) => (
                    <div>
                      <div>
                        <h4 className="fragranceTopTitle">{item.title}</h4>
                      </div>
                      <div>{item.olfactive_note}</div>
                    </div>
                  ))}
                </div>
              ) : null}

              {/* product_description */}
              {relatedTop.length > 0 ? (
                <div>
                  <Divider />
                  <div>
                    <h4 className="fragranceTopTitle">Description</h4>
                  </div>
                  <div>
                    <div>{description}</div>
                  </div>
                </div>
              ) : null}

              {/* claim */}
              {relatedTop.length > 0 ? (
                <div>
                  <Divider />
                  <div>
                    <h4 className="fragranceTopTitle">Claim</h4>
                  </div>
                  <div>
                    <div>{claim}</div>
                  </div>
                </div>
              ) : null}
            </Spin>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
